import PropTypes from 'prop-types';
import { cloneElement, forwardRef, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { noop } from 'lib/utils';

import Icon from '../Icon';
import Text from '../Text';
import IconRight from './IconRight';
import InputField from './InputField';

const Input = forwardRef(
  (
    {
      'data-test-selector': dataTestSelector,
      autoComplete = 'off',
      className,
      defaultValue = null,
      disabled = false,
      error = false,
      full = false,
      helperLink,
      iconLeft = null,
      iconRight = null,
      id,
      inputMode,
      label,
      loading,
      maxLength = null,
      message,
      minLength = null,
      name,
      placeholder = 'Escreva aqui',
      required = false,
      success = false,
      type = 'text',
      value = '',
      onBlur = noop,
      onChange = noop,
      onClick = noop,
      onFocus = noop,
      onInvalid = noop,
      onKeyDown = noop,
      onKeyUp = noop,
    },
    ref
  ) => {
    const [inputType, setInputType] = useState(type);

    const onTogglePasswordInputType = () => {
      setInputType((prevState) =>
        prevState === 'password' ? 'text' : 'password'
      );
    };

    const isPasswordInput = type === 'password';

    return (
      <div
        className={twMerge(
          'relative inline-block text-sm text-neutral-low-100 dark:text-neutral-high-200 lg:text-base',
          full && 'w-full',
          className
        )}
        data-test-selector={dataTestSelector}
      >
        {label && (
          <Text
            as="label"
            className="mb-2 inline-block select-none dark:text-neutral-high-100"
            htmlFor={label}
          >
            {required ? `${label}*` : label}
          </Text>
        )}
        {helperLink && (
          <div className="absolute right-0 top-1 cursor-pointer">
            {helperLink}
          </div>
        )}
        <div className="relative">
          {iconLeft &&
            !error &&
            !success &&
            cloneElement(iconLeft, {
              size: 'size2',
              className:
                'absolute inset-y-0 left-0 my-3 mx-2 text-neutral-high-500 dark:text-neutral-low-100',
            })}
          <InputField
            ref={ref}
            autoComplete={autoComplete}
            defaultValue={defaultValue || undefined}
            disabled={disabled}
            error={error}
            full={full}
            iconLeft={!!(iconLeft && !error)}
            iconRight={!!(iconRight || loading || error)}
            id={id || label || null}
            inputMode={inputMode}
            maxLength={maxLength}
            minLength={minLength}
            name={name}
            placeholder={placeholder}
            type={inputType}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            onFocus={onFocus}
            onInvalid={onInvalid}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
          />
          {(!!iconRight || error || loading || success || isPasswordInput) && (
            <div className="absolute inset-y-0 right-0 flex items-center px-2 py-3">
              <IconRight
                error={error}
                iconRight={
                  (isPasswordInput && (
                    <Icon
                      name={
                        inputType === 'password'
                          ? 'visibility'
                          : 'visibility-off'
                      }
                    />
                  )) ||
                  iconRight
                }
                loading={loading}
                success={success}
                onClick={isPasswordInput ? onTogglePasswordInputType : null}
              />
            </div>
          )}
        </div>
        {message && (
          <div className="relative mt-1 block">
            <Text
              className={twJoin(
                'text-xs lg:text-sm',
                error
                  ? 'text-error-300 dark:text-error-200'
                  : 'text-neutral-low-100 dark:text-neutral-high-200'
              )}
              size="size2"
            >
              {message}
            </Text>
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

Input.propTypes = {
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Receives any HTML attr `autocomplete` value
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#Values
   */
  autoComplete: PropTypes.string,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Default value for uncontrolled component
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Whether the input field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Whether the input should render a error state
   */
  error: PropTypes.bool,
  /**
   * Renders input as a full width
   */
  full: PropTypes.bool,
  /**
   * Renders an icon on the left side
   */
  iconLeft: PropTypes.shape(),
  /**
   * Renders an icon on the right side
   */
  iconRight: PropTypes.shape(),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Custom identifier for the input
   */
  id: PropTypes.string,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Change type of virtual-keyboard without force validation
   */
  inputMode: PropTypes.oneOf([
    'none',
    'text',
    'decimal',
    'numeric',
    'tel',
    'search',
    'email',
    'url',
  ]),
  /**
   * Text to be rendered as a label
   */
  label: PropTypes.string,
  /**
   * Whether the input should render a loading state
   */
  loading: PropTypes.bool,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Receives HTML attr `name` value
   */
  name: PropTypes.string,
  /**
   * Support message to be rendered below the input field
   */
  message: PropTypes.string,
  /**
   * Maximum length of value
   */
  maxLength: PropTypes.number,
  /**
   * Minimum length of value
   */
  minLength: PropTypes.number,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Value of the input
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Text that appears in the form control when the it is empty
   */
  placeholder: PropTypes.string,
  /**
   * Whether input has to contain a value to be submittable
   */
  required: PropTypes.bool,
  /**
   * Whether the input should render a success state
   */
  success: PropTypes.bool,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * One of the following HTML types, not all HTML values are
   * valid for this component
   *
   * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input#%3Cinput%3E_types
   */
  type: PropTypes.oneOf([
    'email',
    'date',
    'email',
    'hidden',
    'month',
    'number',
    'password',
    'tel',
    'text',
    'url',
    'week',
    'search',
  ]),
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onKeyDown` event
   */
  onKeyDown: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onKeyUp` event
   */
  onKeyUp: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onFocus` event
   */
  onFocus: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onBlur` event
   */
  onBlur: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onClick` event
   */
  onClick: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onChange` event
   */
  onChange: PropTypes.func,
  /**
   * **DEVELOPMENT USE ONLY**
   *
   * Callback fn to be executed on `onInvalid` event
   */
  onInvalid: PropTypes.func,
};

export default Input;
