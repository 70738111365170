import { appendQsParams, get, post } from 'lib/request';

export const getCouponFeaturedStoresList = async () =>
  get('/coupon/stores/featured');

getCouponFeaturedStoresList.fallback = [];

export const getCouponInfo = async ({ couponId }, opts) =>
  get(`/coupon/${couponId}`, opts);

export const getCouponsList = async (
  { limit, sort, after, status, couponCodeFilter } = {},
  opts
) =>
  get(
    appendQsParams('/coupon', {
      limit,
      after,
      type: sort,
      status,
      coupon_code: couponCodeFilter,
    }),
    opts
  );

export const getCouponPopularStoresList = async ({ limit }) =>
  get(appendQsParams('/coupon/stores/popular', { limit }));

export const postNewCoupon = async ({
  couponCode,
  couponInstructions,
  couponTitle,
  couponUrl,
  storeId,
}) =>
  post('/coupon', {
    body: {
      couponCode,
      couponInstructions,
      couponTitle,
      couponUrl,
      storeId,
    },
  });
