import { twMerge } from 'tailwind-merge';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import placeholder from 'lib/placeholder';

import SidebarBox from './SidebarBox';

import SOCIAL_MEDIA from 'constants/socialMedia';

const SOCIAL_MEDIAS = [
  {
    name: SOCIAL_MEDIA.FACEBOOK.TITLE,
    href: SOCIAL_MEDIA.FACEBOOK.URL,
    icon: <Icon name="facebook" />,
  },
  {
    name: SOCIAL_MEDIA.TWITTER.TITLE,
    href: SOCIAL_MEDIA.TWITTER.URL,
    icon: <Icon name="twitter" />,
  },
  {
    name: SOCIAL_MEDIA.INSTAGRAM.TITLE,
    href: SOCIAL_MEDIA.INSTAGRAM.URL,
    icon: <Icon name="instagram" />,
  },
  {
    name: SOCIAL_MEDIA.LINKEDIN.TITLE,
    href: SOCIAL_MEDIA.LINKEDIN.URL,
    icon: <Icon name="linkedin" />,
  },
  {
    name: SOCIAL_MEDIA.TELEGRAM.TITLE,
    href: SOCIAL_MEDIA.TELEGRAM.URL,
    icon: <Icon name="telegram" />,
  },
];

const SidebarSocialMedia = ({ className, ...props }) => (
  <SidebarBox
    className={twMerge('hidden p-4 xl:flex', className)}
    title={placeholder('titles.followUs')}
    {...props}
  >
    <div className="flex w-full xl:justify-between">
      {SOCIAL_MEDIAS.map(({ href, name, icon }) => (
        <Button
          key={name}
          aria-label={`${name} do Promobit`}
          as="a"
          className="px-3"
          href={href}
          icon={icon}
          rel="noreferrer"
          size="size2"
          target="_blank"
          type="secondary-stroke"
        />
      ))}
    </div>
  </SidebarBox>
);

export default SidebarSocialMedia;
