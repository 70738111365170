import { useQuery } from '@tanstack/react-query';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import Link from 'shopper/components/Link';
import Spinner from 'shopper/components/Spinner';

import StoreWithCouponsSearch from 'components/StoreWithCouponsSearch';

import placeholder from 'lib/placeholder';
import { couponQueryKeys } from 'lib/queryKeys';

import { getCouponsList } from 'services/coupon';

import LatestCouponsItem from './CouponsItem';
import SidebarBox from './SidebarBox';

import LINKS from 'constants/links';

const LatestCoupons = forwardRef(({ className }, ref) => {
  const {
    data: coupons,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: couponQueryKeys.list({ sortType: 'last', sidebar: true }),
    queryFn: ({ signal }) => getCouponsList(undefined, { signal }),
  });

  return (
    <SidebarBox
      ref={ref}
      className={twMerge('p-4', className)}
      title="Cupons de desconto"
    >
      <StoreWithCouponsSearch className="mb-5" />
      {isSuccess && coupons?.length > 0 && (
        <>
          {coupons.map((coupon) => (
            <LatestCouponsItem key={coupon.couponId} {...coupon} />
          ))}
          <div className="flex w-full items-center justify-center pt-4">
            <Link href={LINKS.COUPONS} size="size1">
              {placeholder('actions.seeMoreCoupons')}
            </Link>
          </div>
        </>
      )}
      {isLoading && (
        <div className="flex justify-center p-6">
          <Spinner />
        </div>
      )}
    </SidebarBox>
  );
});

LatestCoupons.displayName = 'LatestCoupons';

export default LatestCoupons;
