import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import Icon from '../Icon';

function Spinner({ className, size = 'size1', ...rest }) {
  return (
    <Icon
      className={twMerge('animate-spin', className)}
      name="loading"
      size={size}
      {...rest}
    />
  );
}

Spinner.propTypes = {
  /**
   * Renders a spinner with a determined size
   */
  size: PropTypes.oneOf(['size1', 'size2', 'size3']),
};

export default Spinner;
